import React, { useState, useRef } from "react";
// import Client from "shopify-buy";
import PropTypes from "prop-types";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

import Context from "../context/storeContext";

// Utils
import Utils from "../utils";

const useAuth =
  process.env.ENABLE_AUTH && process.env.ENABLE_AUTH.toLowerCase() === "true";
if (useAuth) {
  initializeApp({
    apiKey: process.env.AUTH_API_KEY,
    authDomain: process.env.AUTH_DOMAIN,
  });
}
const isBrowser = typeof window !== "undefined";

const ContextProvider = ({ children, defaultDiscount }) => {
  const initialStoreState = {
    oosSuccess: null,
    products: [],
    shop: {},
    productLoaded: false,
    currentProduct: null,
    currentVariant: null,
    currentQuantity: null,
    showOOS: false,
    showPreLaunch: false,
    showDiscount: false,
    params: {
      ...(defaultDiscount &&
      defaultDiscount.shopifyDiscount &&
      Utils.validDiscount(defaultDiscount.shopifyDiscount)
        ? { discount: defaultDiscount.shopifyDiscount.title }
        : undefined),
    },
    altFunction: {
      process: null,
    },
    abTestProcess: null,
    auth: useAuth && isBrowser && getAuth(),
    provider: useAuth && isBrowser && new GoogleAuthProvider(),
    authInit: false,
    user: null,
  };
  const [store, updateStore] = useState(initialStoreState);
  const productCta = useRef();

  return (
    <>
      <Context.Provider
        value={{
          store,
          productCta,
          initializePDP: () => {
            updateStore((prevState) => ({
              ...prevState,
              currentProduct: null,
              currentVariant: null,
            }));
          },
          updateCurrentProduct: (product) => {
            updateStore((prevState) => ({
              ...prevState,
              currentProduct: product,
            }));
          },
          updateCurrentVariant: (active) => {
            updateStore((prevState) => ({
              ...prevState,
              currentVariant: active,
            }));
          },
          updateCurrentQuantity: (quantity) => {
            updateStore((prevState) => ({
              ...prevState,
              currentQuantity: quantity,
            }));
          },
          updateShowOOS: (show) => {
            updateStore((prevState) => ({
              ...prevState,
              showOOS: show,
            }));
          },
          updateShowPreLaunch: (show) => {
            updateStore((prevState) => ({
              ...prevState,
              showPreLaunch: show,
            }));
          },
          updateParams: (params, append) => {
            if (append) {
              updateStore((prevState) => ({
                ...prevState,
                params: { ...prevState.params, ...params },
              }));
            } else {
              updateStore((prevState) => ({
                ...prevState,
                params,
              }));
            }
          },
          updateAltFunction: (altFunction, append) => {
            if (append) {
              updateStore((prevState) => ({
                ...prevState,
                altFunction: { ...prevState.altFunction, ...altFunction },
              }));
            } else {
              updateStore((prevState) => ({
                ...prevState,
                altFunction,
              }));
            }
          },
          updateABTestProcess: (flag) => {
            updateStore((prevState) => ({
              ...prevState,
              abTestProcess: flag,
            }));
          },
          updateProductLoaded: (productLoaded) => {
            updateStore((prevState) => ({
              ...prevState,
              productLoaded,
            }));
          },
          updateShowDiscount: (show) => {
            updateStore((prevState) => ({
              ...prevState,
              showDiscount: show,
            }));
          },
          updateOosSuccess: (success) => {
            updateStore((prevState) => ({
              ...prevState,
              oosSuccess: success,
            }));
          },
          updateUser: (user) => {
            updateStore((prevState) => ({
              ...prevState,
              user,
            }));
          },
          updateAuthInit: (init) => {
            updateStore((prevState) => ({
              ...prevState,
              authInit: init,
            }));
          },
        }}>
        {children}
      </Context.Provider>
    </>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultDiscount: PropTypes.objectOf(PropTypes.any),
};

ContextProvider.defaultProps = {
  defaultDiscount: null,
};

export default ContextProvider;
