import React, { useContext } from "react";
import PropTypes from "prop-types";
// import withFirebaseAuth from "react-with-firebase-auth";
// import { initializeApp } from "firebase/app";
import {
  // getAuth,
  signOut,
} from "firebase/auth";

// import Utils from "quilt/lib/utils";
// import Form from "quilt/lib/components/molecules/form";
// import Input from "quilt/lib/components/atoms/input";
// import Button from "quilt/lib/components/atoms/button";
import Badge from "quilt/lib/components/atoms/badge";

import StoreContext from "../../context/storeContext";

const Logout = ({ color, title }) => {
  const {
    store: { user, auth },
    updateUser,
  } = useContext(StoreContext);

  if (!user || !auth) {
    return null;
  }

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await signOut(auth);
      updateUser(null);
    } catch (error) {
      // ignore error
      updateUser(null);
    }
  };

  return (
    <div className="auth-info">
      <p className="auth-email">{user.email}</p>
      <div
        className="logout"
        role="button"
        onClick={handleLogout}
        onKeyPress={handleLogout}
        tabIndex={0}>
        <Badge color={color}>{title}</Badge>
      </div>
    </div>
  );
};

Logout.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
};

Logout.defaultProps = {
  color: "lightEucalyptus",
  title: "Logout",
};

export default Logout;
