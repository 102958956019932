import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import Input from "quilt/lib/components/atoms/input";
import CloseIcon from "quilt/lib/components/atoms/icons/close";
import useDocsPages from "../../hooks/useDocsPages";
import useOutsideClick from "../../hooks/useOutsideClick";
// import Form from "./form";
// import Utils from "../../utils";
// import CONSTANTS from "../../constants";

const Search = ({ className, id, forwardRef, title, label }) => {
  const docsPrefix = useStaticQuery(graphql`
    query {
      site {
        pathPrefix
      }
    }
  `);
  const pathPrefix = docsPrefix.site.pathPrefix.slice(1);

  let classList = ["search-wrapper"];
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  const [query, setQuery] = useState();
  const [results, setResults] = useState([]);
  const [resultsOpen, setResultsOpen] = useState();

  const docsPages = useDocsPages();
  const searchRef = useRef();

  useOutsideClick(searchRef, () => {
    setResultsOpen(false);
  });

  const getPagePath = ({ page, allPages, prefix }) => {
    const fullPath = page.slug;
    const parent = allPages.find(
      (ap) => ap.node.id === (page.parentPage && page.parentPage.id),
    );
    if (!parent || parent.node.slug === prefix) {
      return fullPath;
    }
    return `${getPagePath({
      page: parent.node,
      allPages,
      prefix,
    })}/${fullPath}`;
  };

  useEffect(() => {
    const resultsBuild = [];
    if (query && query.length > 2) {
      docsPages.map((dp) => {
        const resultPart = {
          pagePath: getPagePath({
            page: dp.node,
            allPages: docsPages,
            prefix: pathPrefix,
          }),
          slug: dp.node.slug,
          title: dp.node.title,
          id: dp.node.id,
        };
        // 1. Title match
        if (
          dp.node.title.toLowerCase().includes(query.toLowerCase()) &&
          !resultsBuild.find((r) => r.slug === dp.node.slug)
        ) {
          resultsBuild.push({
            ...resultPart,
            type: "title",
          });
        }
        // 2. Content match
        if (
          dp.node.content.content
            // dp.node.content.childMarkdownRemark.html
            // .replace(/(<([^>]+)>)/gi, "")
            .toLowerCase()
            .includes(query.toLowerCase()) &&
          !resultsBuild.find((r) => r.slug === dp.node.slug)
        ) {
          resultsBuild.push({
            ...resultPart,
            type: "content",
          });
        }
        // 3. Tags match
        if (
          dp.node.tags &&
          dp.node.tags.includes(query.toLowerCase()) &&
          !resultsBuild.find((r) => r.slug === dp.node.slug)
        ) {
          resultsBuild.push({
            ...resultPart,
            type: "tag",
          });
        }
        // 4. Media match
        if (
          dp.node.media &&
          dp.node.media.filter(
            (m) =>
              m.title.toLowerCase().includes(query.toLowerCase()) ||
              m.description.toLowerCase().includes(query.toLowerCase()),
          ).length &&
          !resultsBuild.find((r) => r.slug === dp.node.slug)
        ) {
          resultsBuild.push({
            ...resultPart,
            type: "media",
          });
        }
        return true;
      });
    }

    setResults(resultsBuild);
    if (resultsBuild.length) {
      setResultsOpen(true);
    } else {
      setResultsOpen(false);
    }
  }, [query]);

  return (
    <div
      className={`${classList.join(" ")}`}
      id={id}
      ref={forwardRef || searchRef}
      onClick={() => setResultsOpen(true)}
      onKeyDown={() => setResultsOpen(true)}
      tabIndex="0"
      role="button">
      <Input
        placeholder={label || "Search docs"}
        className={`docs-search ${resultsOpen ? "docs-search--results" : ""}`}
        title={title || null}
        type="text"
        name="search"
        id="docsSearch"
        color="purple"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        icon={query ? CloseIcon : null}
        onIconClick={() => setQuery()}
      />
      {results && results.length && resultsOpen ? (
        <ul className="docs-results">
          {results.map((result) => (
            <li className="docs-result" key={result.id}>
              <a
                href={`/${result.pagePath}`}
                aria-label={`${result.title} - ${result.type}`}>
                <span>
                  {result.title}{" "}
                  <mark className="docs-result--path">{`/${result.pagePath}`}</mark>
                  <mark className="docs-result--type">
                    Matches: {result.type}
                  </mark>
                </span>
              </a>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  id: PropTypes.string,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  title: PropTypes.string,
  label: PropTypes.string,
};

Search.defaultProps = {
  className: "",
  id: "",
  forwardRef: null,
  title: null,
  label: null,
};

Search.displayName = "Search";

export default Search;
