import { useStaticQuery, graphql } from "gatsby";

export default function useDocsPages() {
  const pages = useStaticQuery(graphql`
    {
      docsPages: allContentfulDocumentationPage(
        filter: { type: { eq: "data" } }
      ) {
        edges {
          node {
            id
            contentful_id
            title
            slug
            type
            parentPage {
              id
              slug
            }
            updatedAt
            content {
              content
              childMarkdownRemark {
                html
                htmlAst
              }
            }
            tags
            media {
              gatsbyImageData(placeholder: NONE, width: 1920)
              file {
                fileName
                url
                contentType
                details {
                  image {
                    width
                    height
                  }
                }
              }
              id
              title
              description
            }
          }
        }
      }
    }
  `);
  return pages.docsPages.edges;
}
