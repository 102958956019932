// import "lattice/src/lattice.scss";
// import "lattice/lib/lattice.js";

function supportsResizeObserver() {
  return (
    "ResizeObserver" in global &&
    "ResizeObserverEntry" in global &&
    "contentRect" in ResizeObserverEntry.prototype
  );
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
  if (!supportsResizeObserver()) {
    import("resize-observer-polyfill");
    console.log(`# ResizeObserver is polyfilled!`);
  }
};

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  // Only get curr position if we are not changing options
  if (
    !prevRouterProps ||
    (prevRouterProps &&
      routerProps.location.pathname !== prevRouterProps.location.pathname)
  ) {
    const currentPosition = getSavedScrollPosition(routerProps.location);
    window.scrollTo(...(currentPosition || [0, 0]));
  }
  return false;
};

// export const onServiceWorkerUpdateReady = () => window.location.reload(true);
