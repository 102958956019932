const CONSTANTS = {
  CURRENCY_SYMBOL: "$",
  CURRENCY: "USD",
  CORS_PROXY: "https://cors-anywhere.herokuapp.com/",
  TRY_NOW_BUTTON_TEXT: "Start Free Trial",
  GIFT_CARD_BUTTON_TEXT: "Add to Cart",
  OOS_BUTTON_TEXT: "Notify Me When In Stock",
  PRE_LAUCH_BUTTON_TEXT: "Get Updates",
  UNAVAILABLE_BUTTON_TEXT: "Notify Me",
  DISCOUNT_BUTTON_TEXT: "Get Discount",
  PRELOAD_TEXT: "Loading Product...",
  DIR_MAP: {
    product: "products",
    collection: "collections",
    page: "page",
    productListing: "product-listing",
  },
  DISCOUNT_CODE: "WELCOME10",
  OOS_QUANTITY: 0,
  DISCOUNT_COPY: "Get $10 off first order",
  PRE_LAUNCH_COPY:
    "This item hasn’t launched yet! You are seeing this because we wanted you to see it first! Sign-up for updates and we will let you know when it goes live!",
  GIFT_COPY: "Include a recipient and note (Optional)",
  GIFT_TERMS_COPY:
    "Gift card purchases are final sale. Promotions and discounts do not apply. You will receive your gift card within 24 hours.",
  GIFT_NOTE_MAX_LENGTH: 500,
  RESERVED_PARAMS: [
    "size",
    "color",
    "firmness",
    "quantity",
    "discount",
    "value",
    "variant",
    "reset",
  ],
  LOCAL_STORAGE_PREFIX: "buffy_",
  LEGACY_STORAGE_VALUES: ["hp", "discount", "menu", "block", "redirect"],
  PDP_PARAMS: ["size", "color", "firmness", "value"],
  OPTIMIZE_PREFIX: "optimize_",
  ALT_MENU_TEXT: "Alt Menu",
  MOBILE_BREAKPOINT: 768,
  BUFFY_LOGO_URL:
    "https://images.ctfassets.net/9tqo559eytsj/425qMKFs7cPFpZByauMxV1/152eb566754aeee87f1c9bc8b04b6595/logo_small.png",
};

export default CONSTANTS;
