import React from "react";
import Axios from "axios";
import * as Breakpoints from "lattice/src/breakpoints.module.scss";
import atob from "atob";
import format from "date-fns/format";
import NextArrow from "quilt/lib/components/atoms/next-arrow";
import PrevArrow from "quilt/lib/components/atoms/prev-arrow";
import Image from "quilt/lib/components/atoms/image";
import CONSTANTS from "../constants";

const isE2E =
  process.env.E2E_TEST && process.env.E2E_TEST.toLowerCase() === "true";

const getMenu = (menus, search) => {
  const menu = menus.find(
    (m) =>
      m.node.title.includes(search) &&
      !m.node.title.includes(CONSTANTS.ALT_MENU_TEXT),
  );
  return menu && menu.node;
};

const getLink = ({ item, type, slug, extras }) => {
  if (item && item.url) {
    return item.url;
  }
  const { domain, DIR_MAP } = extras || {};
  const prefix = `${domain || ""}/`;
  let linkType =
    item &&
    item.content &&
    item.content.sys &&
    item.content.sys.contentType.sys.id
      ? `${
          (DIR_MAP || CONSTANTS.DIR_MAP)[item.content.sys.contentType.sys.id] ||
          item.content.sys.contentType.sys.id
        }/`
      : type || "";
  let linkSlug =
    item && item.content
      ? item.content.slug || item.content.shopifyProduct.handle
      : slug || "";

  if (linkType === "page/") {
    linkType = "";
  }
  if (
    item &&
    item.itemData &&
    item.itemData.quantity &&
    linkSlug.includes("cloud-pillow")
  ) {
    linkSlug = `${linkSlug}?quantity=${item.itemData.quantity}`;
  }
  return `${prefix}${linkType}${linkSlug}`;
};

const validDiscount = (discount) =>
  discount &&
  discount.status === "ACTIVE" &&
  (!discount.endsAt ||
    format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx") < discount.endsAt) &&
  (!discount.usageLimit || discount.asyncUsageCount < discount.usageLimit);

const mapUrlParams = (params) => {
  const mapParams = (params && { ...params }) || {};
  if (typeof window !== "undefined") {
    const urlSplit = window.location.search.split("?");
    if (urlSplit[1]) {
      urlSplit[1].split("&").map((param) => {
        const paramSplit = param.split("=");
        if (paramSplit[0]) {
          mapParams[paramSplit[0]] = paramSplit[1] || null;
        }
        return false;
      });
    }
  }
  return mapParams;
};

const getRedirectContent = (alt) => {
  if (!alt || (!alt.discount && !alt.redirectUrl && !alt.redirectDestination)) {
    return null;
  }
  const redirects = {
    params: mapUrlParams(),
  };
  if (alt.discount && validDiscount(alt.discount.shopifyDiscount)) {
    redirects.params.discount = alt.discount.shopifyDiscount.title;
  }
  if (alt.redirectDestination) {
    if (alt.redirectDestination.sys.contentType.sys.id === "product") {
      redirects.dest = getLink({
        type: "products/",
        slug: alt.redirectDestination.shopifyProduct.handle,
      });
    } else if (alt.redirectDestination.sys.contentType.sys.id) {
      redirects.dest = getLink({
        type: `${
          CONSTANTS.DIR_MAP[alt.redirectDestination.sys.contentType.sys.id]
        }/`,
        slug: alt.redirectDestination.slug,
      });
    }
  }
  const urlSplit = alt.redirectUrl.split("?");
  if (urlSplit[0]) {
    [redirects.dest] = urlSplit;
  }
  if (urlSplit[1]) {
    urlSplit[1].split("&").map((param) => {
      const paramSplit = param.split("=");
      if (paramSplit[0]) {
        redirects.params[paramSplit[0]] = paramSplit[1] || null;
      }
      return false;
    });
  }
  redirects.url = `${redirects.dest || ""}${
    Object.entries(redirects.params).length ? "?" : ""
  }${Object.entries(redirects.params)
    .map(([k, v]) => `${k}${v ? `=${encodeURIComponent(v)}` : ""}`)
    .join("&")}`;

  return redirects;
};

const getAltContent = ({ params, content, type, extras }) => {
  if (!content) {
    return null;
  }
  let altMatch = null;
  const optimizePrefix = extras.OPTIMIZE_PREFIX || CONSTANTS.OPTIMIZE_PREFIX;
  // Check if alternative content is found in params
  const allParams = Object.entries(params);
  for (let i = 0; i < allParams.length; i += 1) {
    const [k, v] = allParams[i];
    altMatch = k.startsWith(optimizePrefix)
      ? content.find(
          (ac) =>
            `${optimizePrefix}${ac.googleOptimizeExperimentId}` === k &&
            ac.googleOptimizeExperimentVariant === v,
        )
      : content.find((ac) => ac.urlParamKey === k && ac.urlParamValue === v);
    if (altMatch) {
      break;
    }
  }
  if (type === "menu" && altMatch) {
    return {
      ...(altMatch.mediaSections && altMatch.mediaSections[0]),
      classNames: altMatch.classNames,
    };
  }

  if (type === "header" && altMatch) {
    return {
      transparent: altMatch.transparentHeader,
      color: altMatch.headerMode,
      announcement: altMatch.announcement,
      classNames: altMatch.classNames,
    };
  }

  if (type === "redirect" && altMatch) {
    const redirect = getRedirectContent(altMatch);
    return {
      mediaSections: altMatch.mediaSections,
      classNames: altMatch.classNames,
      redirect,
    };
  }
  return altMatch && altMatch.mediaSections;
};

const getMediaBlock = (blocks, search) => {
  const block = blocks.find((m) => m.node.title.includes(search));
  return block && block.node;
};

const slugify = (string) => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

const dashToCamelCase = (options) => {
  const str = options.str || "";
  const sep = options.sep || "";
  const capFirst = options.capFirst || false;
  let word = 0;
  return str.replace(/(-|^)([^-]?)/g, (_, prep, letter) => {
    const upperLetter = !word && !capFirst ? letter : letter.toUpperCase();
    word += 1;
    return (prep && sep) + upperLetter;
  });
};

const stripHtml = (str) => str && str.replace(/(<([^>]+)>)/gi, "").trim();

const getFromPrice = (variants) => {
  let min = Math.trunc(variants[0].node.price);

  for (let i = 1, len = variants.length; i < len; i += 1) {
    const v = Math.trunc(variants[i].node.price);
    min = v < min ? v : min;
  }

  return min;
};

const isMarkdown = (text, html) =>
  text.includes("\n") || (html.match(/<\//g) || []).length > 1;

const isGiftCardProduct = (product) =>
  product &&
  ((product.tags && product.tags.includes("gift-card")) ||
    (product.shopifyProduct &&
      product.shopifyProduct.tags.includes("gift-card")));

const productHasNoInventory = (product) =>
  product &&
  ((product.tags && product.tags.includes("gift-card")) ||
    (product.shopifyProduct &&
      product.shopifyProduct.tags.includes("gift-card")));

const getFirstAvailableVariant = (product) => {
  const defaultParams = {};
  if (product.defaultOptions && product.defaultOptions.length) {
    product.defaultOptions.map((o) => {
      if (o.sys.contentType.sys.id === "productOption") {
        if (
          o.shopifyOption &&
          o.shopifyOption.productHandle === product.shopifyProduct.handle
        ) {
          defaultParams[o.shopifyOption.optionName.toLowerCase()] =
            o.shopifyOption.name;
        }
      } else if (o.sys.contentType.sys.id === "productColor") {
        if (
          o.shopifyColorOption &&
          o.shopifyColorOption.productHandle === product.shopifyProduct.handle
        ) {
          defaultParams.color = o.shopifyColorOption.color;
        }
      }
      return false;
    });
  }

  const firstVariant = product.shopifyProduct.variants.edges.find(
    (v) => v.node.availableForSale,
  );
  const firstDefaultVariant = product.shopifyProduct.variants.edges.find(
    (v) => {
      const optionList = v.node.title
        .split(" / ")
        .map((ol) => ol.toLowerCase());
      let hasOption = true;
      if (Object.keys(defaultParams).length) {
        hasOption =
          hasOption &&
          ((defaultParams.size &&
            optionList.includes(defaultParams.size.toLowerCase())) ||
            !defaultParams.size) &&
          ((defaultParams.color &&
            optionList.includes(defaultParams.color.toLowerCase())) ||
            !defaultParams.color) &&
          ((defaultParams.firmness &&
            optionList.includes(defaultParams.firmness.toLowerCase())) ||
            !defaultParams.firmness) &&
          ((defaultParams.value &&
            optionList.includes(defaultParams.value.toLowerCase())) ||
            !defaultParams.value);
      }
      hasOption = hasOption && v.node.availableForSale;
      return hasOption;
    },
  );
  const variant = firstDefaultVariant || firstVariant;
  return variant && variant.node;
};

const isAvailable = (variant, oosQty, product) => {
  const noQty =
    typeof variant.quantityAvailable === "undefined" ||
    variant.quantityAvailable === null ||
    productHasNoInventory(product);
  return (
    (noQty && variant.availableForSale) ||
    variant.quantityAvailable > (oosQty || CONSTANTS.OOS_QUANTITY)
  );
};

const mapVariantOptions = (product, variant) => {
  // Search the variant to build a mapping
  const optionsMap = {};
  const activeOpts = variant.title.split(" / ");
  activeOpts.map((ao) => {
    const optionMatch = product.shopifyProduct.options.find((o) =>
      o.values.includes(ao),
    );
    if (optionMatch) {
      optionsMap[optionMatch.name.toLowerCase()] = ao;
    }
    return true;
  });
  return optionsMap;
};

const isOOS = ({
  option,
  activeVariant,
  options,
  variants,
  oosQty,
  noInventory,
}) => {
  if (!activeVariant || !activeVariant.title || noInventory) {
    return false;
  }
  // Given active variant title, look for variants that match variant options
  // NOT included in this option group
  const activeOpts = activeVariant.title.split(" / ");
  let spliceIndex = -1;
  options.values.map((v) => {
    const optIndex = activeOpts.indexOf(v);
    if (optIndex !== -1) {
      spliceIndex = optIndex;
      activeOpts.splice(optIndex, 1);
    }
    return true;
  });
  activeOpts.splice(spliceIndex, 0, option);
  const checkVariantTitle = activeOpts.join(" / ");

  const checkVariant = variants.find((v) => v.title === checkVariantTitle);
  return checkVariant && !isAvailable(checkVariant, oosQty);
};

const imageTypeFilter = (image) => image;
const getCarouselSettings = (type, extras) => {
  if (type === "ksp") {
    return {
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: false,
        centerMode: true,
        initialSlide: 2,
        responsive: [
          {
            breakpoint: parseInt(Breakpoints.gridDesktop, 10),
            settings: {
              dots: true,
            },
          },
        ],
      },
    };
  }
  if (type === "trial") {
    return {
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: false,
        responsive: [
          {
            breakpoint: parseInt(Breakpoints.gridTablet, 10),
            settings: {
              dots: true,
              lazyLoad: "ondemand",
            },
          },
        ],
      },
    };
  }
  if (type === "collection") {
    return {
      fullWidth: false,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: true,
        lazyLoad: "progressive",
        nextArrow: (
          <NextArrow label={(extras && extras.headline) || "Product"} />
        ),
        prevArrow: (
          <PrevArrow label={(extras && extras.headline) || "Product"} />
        ),
        responsive: [
          {
            breakpoint: parseInt(Breakpoints.gridTablet, 10),
            settings: {
              lazyLoad: false,
            },
          },
        ],
      },
    };
  }
  if (type === "review") {
    return {
      fullWidth: true,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: true,
        lazyLoad: "progressive",
        nextArrow: <NextArrow label="Review" />,
        prevArrow: <PrevArrow label="Review" />,
        responsive: [
          {
            breakpoint: parseInt(Breakpoints.gridTablet, 10),
            settings: {
              lazyLoad: false,
            },
          },
        ],
      },
    };
  }
  if (type === "product-hero") {
    const images = extras && extras.images;
    return {
      grid: false,
      settings: {
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        dots: true,
        lazyLoad: "progressive",
        dotsClass: `slick-dots slick-thumb ${
          images && images.length > 4 ? "slick-thumb--small" : ""
        }`,
        customPaging: (i) => {
          const imageSources =
            images[i].mobileBgImageData && images[i].bgImageData
              ? {
                  desktop: images[i].bgImageData,
                  mobile: images[i].mobileBgImageData,
                }
              : null;
          return (
            <button type="button">
              {images[i].description || `Product Image ${i + 1}`}
              <Image
                image={imageSources || images[i].bgImageData}
                alt={images[i].bgImageData.description || "Product Thumbnail"}
                className="product-thumb"
                loading="eager"
                as="span"
                width={70}
              />
            </button>
          );
        },
      },
    };
  }
  if (type === "press-small") {
    return {
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: false,
        centerMode: true,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: parseInt(Breakpoints.gridDesktop, 10),
            settings: {
              dots: true,
            },
          },
        ],
      },
    };
  }
  if (type === "free-block") {
    return {
      fullWidth: true,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: true,
        responsive: [
          {
            breakpoint: parseInt(Breakpoints.gridDesktop, 10),
            settings: {
              dots: true,
            },
          },
        ],
      },
    };
  }
  if (type === "lifestyle") {
    return {
      fullWidth: true,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        variableWidth: true,
        lazyLoad: "progressive",
        arrows: false,
      },
    };
  }
  return {};
};

const getMediaGroupProps = (group) => {
  let route = null;
  if (group.route && group.route.sys) {
    if (group.route.sys.contentType.sys.id === "product") {
      route = getLink({
        type: "products/",
        slug: group.route.shopifyProduct.handle,
        extras: {
          domain: group.domain,
          DIR_MAP: group.DIR_MAP,
        },
      });
    } else if (group.route.sys.contentType.sys.id) {
      route = getLink({
        type: `${
          (group.DIR_MAP || CONSTANTS.DIR_MAP)[
            group.route.sys.contentType.sys.id
          ]
        }/`,
        slug: group.route.slug,
        extras: {
          domain: group.domain,
          DIR_MAP: group.DIR_MAP,
        },
      });
    }
  }
  return {
    props: {
      route,
    },
  };
};

const getMediaBlockProps = (block) => {
  if (block.type === "ksp") {
    return {
      title: block.title,
      shortText: block.shortText,
      props: {
        svg:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.file.url
            : null,
        name: block.shortText,
        width:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.width) ||
          null,
        height:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.height) ||
          null,
      },
    };
  }
  if (block.type === "story-block") {
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        imagePosition: block.imagePosition || "left",
        video:
          block.video &&
          block.video.file &&
          block.video.file.contentType.includes("video")
            ? block.video.file.url
            : null,
        videoName: (block.video && block.video.title) || "Video",
        videoType:
          block.video && block.video.file && block.video.file.contentType,
      },
    };
  }
  if (block.type === "related-block") {
    const cta = {};
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.label =
        stripHtml(block.shortText) || block.displayTitle || block.title || "";
      cta.color = block.ctaColor;
      if (block.ctaContent.sys.contentType.sys.id === "product") {
        cta.route = getLink({
          type: "products/",
          slug: block.ctaContent.shopifyProduct.handle,
          extras: block.extras,
        });
      } else if (block.ctaContent.sys.contentType.sys.id) {
        cta.route = getLink({
          type: `${
            (block.DIR_MAP || CONSTANTS.DIR_MAP)[
              block.ctaContent.sys.contentType.sys.id
            ]
          }/`,
          slug: block.ctaContent.slug,
          extras: block.extras,
        });
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.label =
        stripHtml(block.shortText) || block.displayTitle || block.title || "";
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
    }
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        cta,
        video:
          block.video &&
          block.video.file &&
          block.video.file.contentType.includes("video")
            ? block.video.file.url
            : null,
        videoName: (block.video && block.video.title) || "Video",
        videoType:
          block.video && block.video.file && block.video.file.contentType,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
      },
    };
  }
  if (block.type === "quote-hero") {
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        svg:
          block.supportingImage &&
          block.supportingImage.file &&
          block.supportingImage.file.contentType.includes("svg")
            ? block.supportingImage.file.url
            : null,
        name: (block.supportingImage && block.supportingImage.title) || "Quote",
        imagePosition: block.imagePosition,
        color: block.textColor || "dark",
        width:
          (block.supportingImage &&
            block.supportingImage.file &&
            block.supportingImage.file.details &&
            block.supportingImage.file.details.image &&
            block.supportingImage.file.details.image.width) ||
          null,
        height:
          (block.supportingImage &&
            block.supportingImage.file &&
            block.supportingImage.file.details &&
            block.supportingImage.file.details.image &&
            block.supportingImage.file.details.image.height) ||
          null,
        svgContent:
          block.supportingImage &&
          block.supportingImage.svg &&
          block.supportingImage.svg.content,
      },
    };
  }
  if (block.type === "trial") {
    return {
      title: block.title,
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        svg:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.file.url
            : null,
        name: block.shortText,
        width:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.width) ||
          null,
        height:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.height) ||
          null,
      },
    };
  }
  if (block.type === "collection") {
    const showPrice =
      block.extras &&
      block.extras.classes &&
      block.extras.classes.includes("show-price");
    const showDiscount =
      block.extras &&
      block.extras.classes &&
      block.extras.classes.includes("show-discount");
    const showColor =
      block.extras &&
      block.extras.classes &&
      block.extras.classes.includes("show-color");

    let sortedColors = showColor && block.extras && block.extras.colors;
    // Sort Contentful colors according to Shopify colors
    if (
      showColor &&
      block.shopifyProduct.options &&
      block.extras &&
      block.extras.colors
    ) {
      const shopifyColors = block.shopifyProduct.options.find(
        (c) => c.name.toLowerCase() === "color",
      );
      if (shopifyColors) {
        sortedColors = [...block.extras.colors].sort(
          (a, b) =>
            shopifyColors.values.indexOf(a.node.shopifyColorOption.color) -
            shopifyColors.values.indexOf(b.node.shopifyColorOption.color),
        );
      }
    }
    // Get default color index if there is one
    const defaultColorIndex =
      sortedColors && block.plpDefaultColor
        ? sortedColors.findIndex(
            (sc) =>
              block.plpDefaultColor.shopifyColorOption.id ===
              sc.node.shopifyColorOption.id,
          )
        : 0;

    const fromPrice =
      showPrice && block.shopifyProduct.variants
        ? getFromPrice(block.shopifyProduct.variants.edges)
        : null;
    const priceInfo = (fromPrice &&
      showDiscount &&
      block &&
      block.extras &&
      block.extras.calcDiscountPrice &&
      block.extras.calcDiscountPrice({
        price: Math.trunc(fromPrice),
        lineItems: block.extras.storeContext.store.checkout.lineItems,
        product: block.shopifyProduct,
        // variant: activeVariant,
        discounts: block.extras.discounts,
        quantity: 1,
        params: block.extras.params,
      })) || {
      price: Math.trunc(fromPrice),
    };

    // Find special images to use
    let specialImage = null;
    if (block.specialCollectionImages && block.extras && block.extras.classes) {
      specialImage = block.specialCollectionImages.find(
        (sci) =>
          sci.classNames &&
          sci.classNames.find((cn) => block.extras.classes.includes(cn)),
      );
    }

    return {
      title: block.title,
      image: block.collectionImage,
      mobileImage: block.mobileCollectionImage,
      extraText:
        showPrice && block.shopifyProduct.variants
          ? `From $${fromPrice}`
          : null,
      shortText: block.plpName || block.heading,
      longText: block.shortDescription,
      supportingImage: block.supportingImage,
      mobileSupportingImage: block.mobileSupportingImage,
      props: {
        cta: {
          route: getLink({
            type: "products/",
            slug: block.shopifyProduct.handle,
            extras: block.extras,
          }),
        },
        colors: sortedColors,
        defaultColorIndex: defaultColorIndex < 0 ? 0 : defaultColorIndex,
        new: block.new,
        priceInfo,
        specialImage: specialImage && specialImage.image,
        mobileSpecialImage: specialImage && specialImage.mobileImage,
        carouselClasses: block.extras && block.extras.classes,
        isGiftCard: isGiftCardProduct(block.shopifyProduct),
      },
    };
  }
  if (block.type === "review") {
    return {
      title: "Review",
      props: {
        review: block.review,
        reviewStar: block.reviewStar,
        reviewModal: {
          setShowReviewModal: block.setShowReviewModal,
          setReviewModalContent: block.setReviewModalContent,
        },
      },
    };
  }
  if (block.type === "hero") {
    const cta = {};
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.label =
        stripHtml(block.shortText) || block.displayTitle || block.title || "";
      cta.color = block.ctaColor;
      if (block.ctaContent.sys.contentType.sys.id === "product") {
        cta.route = getLink({
          type: "products/",
          slug: block.ctaContent.shopifyProduct.handle,
          extras: block.extras,
        });
      } else if (block.ctaContent.sys.contentType.sys.id) {
        cta.route = getLink({
          type: `${
            (block.DIR_MAP || CONSTANTS.DIR_MAP)[
              block.ctaContent.sys.contentType.sys.id
            ]
          }/`,
          slug: block.ctaContent.slug,
          extras: block.extras,
        });
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.label =
        stripHtml(block.shortText) || block.displayTitle || block.title || "";
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
    }
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      supportingImage: imageTypeFilter(block.supportingImage),
      mobileSupportingImage: imageTypeFilter(block.mobileSupportingImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        cta,
        color: block.textColor || "dark",
        useGutters: block.useGutters,
        backgroundColor: block.backgroundColor,
        imagePosition: block.imagePosition,
        displayTitle: block.displayTitle,
      },
    };
  }
  if (block.type === "product-block" || block.type === "guide-item") {
    const cta = {};
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.label =
        stripHtml(block.shortText) || block.displayTitle || block.title || "";
      cta.color = block.ctaColor;
      if (block.ctaContent.sys.contentType.sys.id === "product") {
        cta.route = getLink({
          type: "products/",
          slug: block.ctaContent.shopifyProduct.handle,
          extras: block.extras,
        });
      } else if (block.ctaContent.sys.contentType.sys.id) {
        cta.route = getLink({
          type: `${block.ctaContent.sys.contentType.sys.id}/`,
          slug: block.ctaContent.slug,
          extras: block.extras,
        });
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.label =
        stripHtml(block.shortText) || block.displayTitle || block.title || "";
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
    }
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        imagePosition: block.imagePosition || "left",
        cta,
        useGutters: block.useGutters,
        video:
          block.video &&
          block.video.file &&
          block.video.file.contentType.includes("video")
            ? block.video.file.url
            : null,
        videoName: (block.video && block.video.title) || "Video",
        videoType:
          block.video && block.video.file && block.video.file.contentType,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
      },
    };
  }
  if (block.type === "press-small") {
    return {
      title: block.title,
      shortText: block.shortText,
      props: {
        svg:
          block.image &&
          block.image.file &&
          block.image.file.contentType.includes("svg")
            ? block.image.file.url
            : null,
        name: (block.image && block.image.title) || "Press",
        width:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.width) ||
          null,
        height:
          (block.image &&
            block.image.file &&
            block.image.file.details &&
            block.image.file.details.image &&
            block.image.file.details.image.height) ||
          null,
      },
    };
  }
  if (block.type === "free-block") {
    const cta = {};
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.label =
        stripHtml(block.shortText) || block.displayTitle || block.title || "";
      cta.color = block.ctaColor;
      if (block.ctaContent && block.ctaContent.sys) {
        if (block.ctaContent.sys.contentType.sys.id === "product") {
          cta.route = getLink({
            type: "products/",
            slug: block.ctaContent.shopifyProduct.handle,
            extras: block.extras,
          });
        } else if (block.ctaContent.sys.contentType.sys.id) {
          cta.route = getLink({
            type: `${
              (block.DIR_MAP || CONSTANTS.DIR_MAP)[
                block.ctaContent.sys.contentType.sys.id
              ]
            }/`,
            slug: block.ctaContent.slug,
            extras: block.extras,
          });
        }
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.label =
        stripHtml(block.shortText) || block.displayTitle || block.title || "";
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
      if (cta.route === "#") {
        cta.inactive = true;
      }
    }
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      supportingImage: block.supportingImage,
      mobileSupportingImage: block.mobileSupportingImage,
      props: {
        svg:
          block.supportingImage &&
          block.supportingImage.file &&
          block.supportingImage.file.contentType.includes("svg")
            ? block.supportingImage.file.url
            : null,
        name: (block.supportingImage && block.supportingImage.title) || "Quote",
        color: block.textColor || "dark",
        cta,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
        imagePosition: block.imagePosition,
        width:
          (block.supportingImage &&
            block.supportingImage.file &&
            block.supportingImage.file.details &&
            block.supportingImage.file.details.image &&
            block.supportingImage.file.details.image.width) ||
          null,
        height:
          (block.supportingImage &&
            block.supportingImage.file &&
            block.supportingImage.file.details &&
            block.supportingImage.file.details.image &&
            block.supportingImage.file.details.image.height) ||
          null,
      },
    };
  }
  if (block.type === "info-block") {
    return {
      title: block.title,
      shortText: block.shortText,
      longText: block.longText && block.longText.childMarkdownRemark.html,
    };
  }
  if (block.type === "video") {
    return {
      title: block.title,
      props: {
        video:
          block.video &&
          block.video.file &&
          block.video.file.contentType.includes("video")
            ? block.video.file.url
            : null,
        name: (block.video && block.video.title) || "Video",
        type: block.video && block.video.file && block.video.file.contentType,
      },
    };
  }
  if (block.type === "image") {
    return {
      title: block.title,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
    };
  }
  if (block.type === "text") {
    return {
      title: block.title,
      longText: block.longText && block.longText.longText,
      props: {
        backgroundColor: block.backgroundColor,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
      },
    };
  }
  if (block.type === "editorial" || block.type === "large-editorial") {
    return {
      title: block.title,
      longText: block.longText && block.longText.longText,
      props: {
        backgroundColor: block.backgroundColor,
        longHtml: block.longText && block.longText.childMarkdownRemark.html,
      },
    };
  }
  if (block.type === "lifestyle") {
    return {
      title: block.title,
      useTitle: true,
      image: imageTypeFilter(block.image),
      mobileImage: imageTypeFilter(block.mobileImage),
    };
  }
  if (block.type === "email-hero") {
    return {
      title: block.title,
      shortText: block.shortText,
      longText: block.longText && block.longText.longText,
      props: {
        useGutters: block.useGutters,
        color: block.textColor || "dark",
        backgroundColor: block.backgroundColor,
        oosEmailList: block.extras && block.extras.OOS_EMAIL_LIST,
        extras: {
          DEV: block.extras && block.extras.DEV,
          KLAVIYO_API_KEY: block.extras && block.extras.KLAVIYO_API_KEY,
          CORS_PROXY: block.extras && block.extras.CORS_PROXY,
        },
      },
    };
  }
  if (block.type === "pencilbar") {
    const cta = {
      text: block.shortText || block.ctaText,
      color: block.backgroundColor || block.ctaColor,
    };
    if (block.ctaContent) {
      cta.text = block.ctaText;
      cta.color = block.ctaColor;
      if (block.ctaContent && block.ctaContent.sys) {
        if (block.ctaContent.sys.contentType.sys.id === "product") {
          cta.route = getLink({
            type: "products/",
            slug: block.ctaContent.shopifyProduct.handle,
            extras: block.extras,
          });
        } else if (block.ctaContent.sys.contentType.sys.id) {
          cta.route = getLink({
            type: `${
              (block.DIR_MAP || CONSTANTS.DIR_MAP)[
                block.ctaContent.sys.contentType.sys.id
              ]
            }/`,
            slug: block.ctaContent.slug,
            extras: block.extras,
          });
        }
      }
    }
    if (block.ctaUrl) {
      cta.text = block.ctaText;
      cta.color = block.ctaColor;
      cta.route = getLink({
        item: {
          url: block.ctaUrl,
        },
      });
      if (cta.route === "#") {
        cta.inactive = true;
      }
    }
    return {
      title: block.title,
      useTitle: true,
      image: block.image,
      props: {
        cta,
        displayTitle: block.displayTitle,
      },
    };
  }
  return {};
};

const decodeId = (id) => {
  let did = atob(id);
  did = did.substring(did.lastIndexOf("/") + 1, did.length);
  return did;
};

const buildCustomAttributes = ({ tags, giftOptions }) => {
  const customAttr = [];
  if (tags.includes("trial")) {
    customAttr.push({
      key: "trial_product",
      value: tags.includes("trial").toString(),
    });
  }
  if (tags.includes("gift-card")) {
    customAttr.push({
      key: "gift_card",
      value: tags.includes("gift-card").toString(),
    });
  }
  if (giftOptions && (giftOptions.note || giftOptions.name)) {
    customAttr.push({
      key: "gift_name",
      value: giftOptions.name,
    });
    customAttr.push({
      key: "gift_note",
      value: giftOptions.note,
    });
  }
  return customAttr;
};

const buildTrialPropertyString = ({ tags }) =>
  `&properties[trial_product]=${tags.includes("trial").toString()}`;

const lineItemPrice = (lineItem, quantity) => {
  const itemFullPrice = lineItem.variant.price;
  // Get discounted value
  const bundleDiscount = lineItem.customAttributes.find(
    (ca) => ca.key === "bundle_discount",
  );
  const itemBundleDiscount = bundleDiscount ? bundleDiscount.value / 100 : 0;
  // $0 if trial procuct
  const itemTrial = lineItem.customAttributes.find(
    (ca) =>
      ca.key === "trial_product" &&
      ca.value &&
      ca.value.toLowerCase() === "true",
  );

  const itemPrice = itemTrial
    ? 0
    : Math.trunc(itemFullPrice * quantity - itemBundleDiscount);
  return itemPrice;
};

const addProductToCart = async ({
  e,
  currentVariant,
  currentProduct,
  currentQuantity,
  addVariantToCart,
  oosQuantity,
  giftOptions,
}) => {
  e.preventDefault();
  if (isAvailable(currentVariant, oosQuantity, currentProduct)) {
    const customAttributes = buildCustomAttributes({
      tags: currentProduct.shopifyProduct.tags,
      giftOptions: isGiftCardProduct(currentProduct) && giftOptions,
    });
    await addVariantToCart(
      currentVariant.id,
      currentQuantity || 1,
      customAttributes,
    );
    // trackCartPixel(productTitle, active)
    // window.open(checkout.webUrl)
  }
};

const corsFetch = ({ url, data, corsProxy, dev }) => {
  const isBrowser = typeof window !== `undefined`;
  return dev &&
    isBrowser &&
    !window.location.host.includes(process.env.DEV_PORT)
    ? Axios.post(
        `${isE2E ? "" : corsProxy || CONSTANTS.CORS_PROXY}${url}`,
        data,
      )
    : Axios.post("/.netlify/functions/api-call", {
        api_url: url,
        data,
      });
};

const calcBundleDiscountPrice = (bundleInfo, price) => {
  if (bundleInfo.discount.discountType === "fixed") {
    return Math.trunc(price - bundleInfo.discount.discountValue);
  }
  if (bundleInfo.discount.discountType === "percent") {
    return (price - price * (bundleInfo.discount.discountValue / 100)).toFixed(
      2,
    );
  }
  return price;
};

const findLongestWord = (str) => {
  if (typeof str !== "string") {
    return 0;
  }
  const longestWord = str
    .split(" ")
    .reduce(
      (longest, currentWord) =>
        currentWord.length > longest.length ? currentWord : longest,
      "",
    );
  return longestWord.length;
};

const emailIsValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export default {
  getMenu,
  getAltContent,
  getLink,
  getMediaBlock,
  dashToCamelCase,
  getCarouselSettings,
  getMediaBlockProps,
  getMediaGroupProps,
  isMarkdown,
  isOOS,
  decodeId,
  slugify,
  buildCustomAttributes,
  buildTrialPropertyString,
  addProductToCart,
  corsFetch,
  calcBundleDiscountPrice,
  productHasNoInventory,
  isGiftCardProduct,
  isAvailable,
  getFromPrice,
  mapVariantOptions,
  lineItemPrice,
  findLongestWord,
  emailIsValid,
  getFirstAvailableVariant,
  stripHtml,
};
