import { useStaticQuery, graphql } from "gatsby";
import hierarchy from "quilt/lib/utils/hierarchy";

export default function useSiteMenus() {
  const menus = useStaticQuery(graphql`
    {
      docsMenu: allContentfulExternalMenu(
        filter: { slug: { eq: "data-docs-menu" } }
      ) {
        edges {
          node {
            id
            slug
            title
            menu {
              id
              entryId
              title
              url
              itemData
              depth
              parentId
              slug
              entryTitle
              contentType
            }
          }
        }
      }
    }
  `);
  const menu =
    menus && menus.docsMenu.edges && menus.docsMenu.edges[0].node.menu;
  const newMenu = hierarchy.createHierarchy(menu);
  return newMenu;
}
