// React & Props
import React from "react";
import PropTypes from "prop-types";
// import { getLCP, getCLS } from "web-vitals";

// Lattice Components
import Lattice from "lattice/lib/components/Lattice";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import LatticeDevLines from "lattice/lib/components/LatticeDevLines";

// Quilt Components
import Preload from "quilt/lib/components/functional/preload";

// Providers
import ContextProvider from "../provider/contextProvider";
import ShopifyContextProvider from "../provider/shopifyContextProvider";

// Internal Components
// import StoreContext from "../context/storeContext";
import DesignHeader from "../components/organisms/design-header";
// import BuffyTracking from "../components/functional/tracking";

// Hooks
import useSiteMenus from "../hooks/useSiteMenus";

// Styles
import "quilt/src/styles/layout.scss";
import "quilt/src/styles/header.scss";
import "quilt/src/styles/footer.scss";

const devLines =
  process.env.DEV_LINES && process.env.DEV_LINES.toLowerCase() === "true";

const Layout = ({ children, pageContext }) => {
  let content = null;
  const menuData = pageContext.menu || useSiteMenus();
  if (pageContext.layout === "page") {
    content = (
      <ContextProvider>
        <ShopifyContextProvider>
          <Preload />
          <Lattice
            className={`buffy ${pageContext.layout}`}
            hideOverflow={devLines}>
            {devLines ? <LatticeDevLines maxRows={1000} /> : null}
            <LatticeGrid
              className="design design--search"
              innerGrid
              fullGrid
              fullWidth
              useGutters>
              <DesignHeader menu={menuData} current={pageContext.id} search />
              {children}
            </LatticeGrid>
          </Lattice>
        </ShopifyContextProvider>
      </ContextProvider>
    );
  } else {
    // 404 page
    content = children;
  }
  return content;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({ layout: PropTypes.string }),
};

Layout.defaultProps = {
  pageContext: {},
};

export default Layout;
