import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import BuffyLogo from "quilt/lib/components/atoms/icons/buffy-logo";
import HamburgerIcon from "quilt/lib/components/atoms/icons/hamburger-icon";
// import PropTypes from "prop-types";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import Accordion from "quilt/lib/components/molecules/accordion";
import Utils from "quilt/lib/utils";
import { navHeight } from "quilt/src/styles/header-styles.module.scss";
import useHover from "../../hooks/hover";
import useScrollPosition from "../../hooks/useScrollPosition";
import Search from "../molecules/search";
import Logout from "../atoms/logout";
import "../../styles/design.scss";

import StoreContext from "../../context/storeContext";

const useAuth =
  process.env.ENABLE_AUTH && process.env.ENABLE_AUTH.toLowerCase() === "true";

const DesignHeader = ({ menu, current, search }) => {
  const {
    store: { user },
  } = useContext(StoreContext);

  const [submenuRef, submenuEl, submenuIsHovered] = useHover(true);
  const [submenuState, setSubmenuState] = useState("init");
  const [submenuAnimateState, setSubmenuAnimateState] = useState(null);
  const [scrollPos, setScrollPos] = useState();
  const mobileExpand = useRef();
  useScrollPosition(({ currPos }) => {
    setScrollPos(currPos.y);
  });

  const handleAnimationStart = () => {
    setSubmenuAnimateState("animating");
  };
  const handleAnimationEnd = () => {
    setSubmenuAnimateState("ending");
    if (submenuState === "hidden") {
      setSubmenuState("init");
    }
  };
  const handleMobileMenu = () => {
    if (submenuAnimateState === "animating") {
      return;
    }

    if (submenuState === "show") {
      setSubmenuState("hidden");
    } else {
      setSubmenuState("show");
    }
  };

  useEffect(() => {
    if (submenuEl) {
      if (submenuState === "show") {
        disableBodyScroll(submenuEl);
      } else {
        enableBodyScroll(submenuEl);
      }
    }
  }, [
    mobileExpand,
    submenuState,
    setSubmenuState,
    submenuIsHovered,
    submenuEl,
  ]);

  const displayNav = !useAuth || (useAuth && user);
  const displayLogout = useAuth && user;

  return (
    <>
      {displayNav ? (
        <div
          className="design__mobile-expand-wrapper"
          onClick={handleMobileMenu}
          ref={mobileExpand}
          role="button"
          tabIndex={0}
          onKeyPress={handleMobileMenu}>
          {submenuState !== "init" ? (
            <div className="design__mobile-close" />
          ) : (
            <HamburgerIcon />
          )}
        </div>
      ) : null}

      <div className="design__logo-wrapper">
        <a href="/" className="design__logo">
          <BuffyLogo />
        </a>
      </div>
      {displayLogout ? <Logout /> : null}
      {displayNav ? (
        <>
          <ul
            role="navigation"
            className={`menu--demi design__nav design__nav--desktop ${
              displayLogout ? "design__nav--auth" : ""
            }`}>
            {menu.map((item) => {
              // Accordion = ({ title, children, className, isHtml, onOpen, onClose })
              const route = Utils.getLink({
                item,
              });
              return (
                <li key={item.id}>
                  {item.items ? (
                    <Accordion
                      title={item.title}
                      showOnInit={
                        current === (item.content && item.content.slug) ||
                        item.items.find(
                          (sm) => current === (sm.content && sm.content.slug),
                        ) !== undefined
                      }
                      titleRoute={route}
                      className={`design__topnav ${
                        current === (item.content && item.content.slug)
                          ? "design__currnav"
                          : ""
                      }`}>
                      <ul className="design__subnav">
                        {item.items.map((sitem) => {
                          const sroute = Utils.getLink({ item: sitem });
                          return (
                            <li key={sitem.id}>
                              <Link
                                to={sroute}
                                className={
                                  current ===
                                  (sitem.content && sitem.content.slug)
                                    ? "design__currnav"
                                    : ""
                                }>
                                {sitem.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </Accordion>
                  ) : (
                    <Link
                      to={route}
                      className={
                        current === (item.content && item.content.slug)
                          ? "design__currnav"
                          : ""
                      }>
                      {item.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>

          <ul
            role="navigation"
            className={`menu--demi design__nav design__nav--mobile design__nav--${submenuState} ${
              displayLogout ? "design__nav--auth" : ""
            }`}
            ref={submenuRef}
            onAnimationStart={handleAnimationStart}
            onAnimationEnd={handleAnimationEnd}>
            {menu.map((item) => {
              const route = Utils.getLink({
                item,
              });
              return (
                <li key={item.id}>
                  {item.items ? (
                    <Accordion
                      title={item.title}
                      showOnInit={
                        current === (item.content && item.content.slug) ||
                        item.items.find(
                          (sm) => current === (sm.content && sm.content.slug),
                        ) !== undefined
                      }
                      titleRoute={route}
                      className={`design__topnav ${
                        current === (item.content && item.content.slug)
                          ? "design__currnav"
                          : ""
                      }`}>
                      <ul className="design__subnav">
                        {item.items.map((sitem) => {
                          const sroute = Utils.getLink({ item: sitem });
                          return (
                            <li key={sitem.id}>
                              <Link
                                to={sroute}
                                className={
                                  current ===
                                  (sitem.content && sitem.content.slug)
                                    ? "design__currnav"
                                    : ""
                                }
                                onClick={handleMobileMenu}
                                onKeyPress={handleMobileMenu}>
                                {sitem.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </Accordion>
                  ) : (
                    <Link
                      to={route}
                      className={
                        current === (item.content && item.content.slug)
                          ? "design__currnav"
                          : ""
                      }
                      onClick={handleMobileMenu}
                      onKeyPress={handleMobileMenu}>
                      {item.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      ) : null}

      {displayNav && search ? (
        <Search
          className={
            scrollPos < (navHeight - 16) * -1 ? "search-wrapper--sticky" : ""
          }
        />
      ) : null}
    </>
  );
};

DesignHeader.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.shape({
        slug: PropTypes.string,
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          content: PropTypes.shape({
            slug: PropTypes.string,
          }),
        }),
      ),
    }),
  ).isRequired,
  current: PropTypes.string,
  search: PropTypes.bool,
};

DesignHeader.defaultProps = {
  current: "index",
  search: false,
};

export default DesignHeader;
